import { ChangeDetectionStrategy, Component, Directive, Input, ViewEncapsulation } from '@angular/core';

// noinspection TsLint
@Component({
  selector: 'prep-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  host: { 'class': 'prep-card' },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrepCard {
}

// noinspection TsLint
@Component({
  selector: 'prep-card-header',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { 'class': 'prep-card-header' },
  template: `
    <div class="prep-card-header-heading-group">
      <ng-content select="prep-card-header-heading"></ng-content>
      <ng-content select="prep-card-header-subheading"></ng-content>
    </div>
    <ng-content></ng-content>
    <ng-content select="prep-card-header-actions"></ng-content>
  `
})
export class PrepCardHeader {
}

// noinspection TsLint
@Component({
  selector: 'prep-card-content',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { 'class': 'prep-card-content' },
  template: `
    <ng-content></ng-content>`
})
export class PrepCardContent {
}

// noinspection TsLint
@Directive({
  selector: 'prep-card-header-heading',
  host: { 'class': 'prep-card-header-heading' }
})
export class PrepCardHeaderTitle {
}

// noinspection TsLint
@Directive({
  selector: 'prep-card-header-subheading',
  host: { 'class': 'prep-card-header-subheading' }
})
export class PrepCardHeaderSubTitle {
}

// noinspection TsLint
@Directive({
  selector: 'prep-card-header-actions',
  host: { 'class': 'prep-card-header-actions' }
})
export class PrepCardHeaderActions {
}

// noinspection TsLint
@Directive({
  selector: 'prep-card-actions',
  host: {
    'class': 'prep-card-actions',
    '[class.prep-card-actions-align-end]': 'align === "end"',
  }
})
export class PrepCardActions {
  /** Position of the actions inside the card. */
  @Input() align: 'start' | 'end' = 'start';
}
