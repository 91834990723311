import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  PrepCard,
  PrepCardActions,
  PrepCardContent,
  PrepCardHeader,
  PrepCardHeaderActions,
  PrepCardHeaderSubTitle,
  PrepCardHeaderTitle
} from './card.component';

const cardComponents = [
  PrepCard,
  PrepCardHeader,
  PrepCardHeaderTitle,
  PrepCardHeaderSubTitle,
  PrepCardHeaderActions,
  PrepCardContent,
  PrepCardActions
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...cardComponents
  ],
  exports: [
    ...cardComponents
  ]
})
export class PrepCardModule {
}
