import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prep-toolbar-release-notes',
  templateUrl: './toolbar-release-notes.component.html',
  styleUrls: ['./toolbar-release-notes.component.scss']
})
export class ToolbarReleaseNotesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  clickGoToReleaseNotes() {
    const link = document.createElement('a');
    link.target = '_blank';
    // link.href = 'https://app.productstash.io/roadmaps/5f560a4ac67165002916ff42/public#completed';
    link.href = 'https://app.loopedin.io/roadmaps/5f560a4ac67165002916ff42/public#completed';
    link.setAttribute('visibility', 'hidden');
    link.click();
  }

}
