<div class="toolbar-user" (furyClickOutside)="onClickOutside()" fxFlex="grow">
  <button class="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen" fxFlex="grow">
    <span fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="list-item-icon">account_circle</mat-icon>
    </span>
  </button>

  <div class="dropdown" [class.open]="isOpen">
    <div class="content">
      <div class="list">
        <div class="list-item" matRipple (click)="profile()">
          <mat-icon class="list-item-icon" >account_circle</mat-icon>
          <span>Profile</span>
        </div>
        <mat-divider></mat-divider>
        <div class="list-item" matRipple (click)="logoutClicked()">
          <mat-icon class="list-item-icon">exit_to_app</mat-icon>
          <span>Logout</span>
        </div>
      </div>
    </div>
  </div>
</div>