<div (prepClickOutside)="onClickOutside()" class="toolbar-notifications" fxFlex="grow" fxLayout="row"
  fxLayoutAlign="start center">
  <button (click)="toggleDropdown()" [class.open]="isOpen" class="button" fxFlex="grow" mat-button type="button"
  matTooltip="NOTIFICATION" matTooltipClass="table_tooltip_job_filename" matTooltipPosition="below">
    <mat-icon [matBadgeHidden]="notifyCount === 0" [matBadge]="notifyCount" class="icon">
      notifications_active
    </mat-icon>
  </button>

  <div [class.open]="isOpen" class="dropdown">
    <prep-card>
      <prep-card-header class="dropdown-header">
        <prep-card-header-heading>
          Notifications
        </prep-card-header-heading>
        <prep-card-header-subheading style="color: #1976d2;">
          You have {{ notifyCount }} new notifications.
        </prep-card-header-subheading>

        <!-- <prep-card-header-actions>
          <button mat-icon-button type="button">
            <mat-icon class="icon">settings</mat-icon>
          </button>
        </prep-card-header-actions> -->
      </prep-card-header>

      <prep-card-content class="dropdown-card-content">
        <prep-scrollbar class="dropdown-content">
          <div fxLayout="column">
            <!-- [ngClass]="notification.colorClass" -->
            <ng-container *ngFor="let notification of notifications; let last = last">
              <div (click)="markAsRead(notification)" [@listFade] [class.read]="notification.read" class="notification"
                fxLayout="row" fxLayoutAlign="start center" matRipple>

              
                <!-- <mat-icon class="icon">{{ notification.icon }}</mat-icon> -->
                <div class="label" fxLayout="column" [matTooltip]="notification.filename " matTooltipClass="table_tooltip_job_filename" matTooltipPosition="left">
                  <div class="time" style="color: #1976d2;word-wrap: break-word !important" 
                  >
                    
                    {{
                      notification.filename ?.length > 17
                      ? notification.filename .substring(0, 10) +
                      "...." +
                      notification.filename .substring(notification.filename .length - 7)
                      : notification.filename 
                      }}
                    
                    </div>
                  <div class="name" style="word-wrap: break-word !important">{{ notification.desc }}</div>
                
                
                </div>
                <span fxFlex></span>
                <button [matTooltip]="'Mark as Read'"
                matTooltipClass="table_tooltip_job_filename" matTooltipPosition="below" (click)="dismiss(notification, $event)" mat-icon-button type="button">
                  <mat-icon class="close">done</mat-icon>
                </button>
        
               
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
          </div>
          <div *ngIf="notifications.length === 0" [@listFade] class="empty">
            All fine! No notifications right now.
          </div>
        </prep-scrollbar>
      </prep-card-content>


      <prep-card-actions *ngIf="false" (click)="markAllAsRead()" class="dropdown-footer" matRipple>
        Mark all as read
      </prep-card-actions>
    </prep-card>
  </div>
</div>