import { Directive } from '@angular/core';

@Directive({
  selector: '[prepTitle],prep-title',
  host: {
    class: 'prep-title'
  }
})
export class TitleDirective {

  constructor() { }

}
