import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });
  baseUrl: string = environment.backend;

  constructor(private http: HttpClient) {}

  getNotifyData(): Observable<any> {
    return this.http.get(
      this.baseUrl + 'accounts/notifications/',
      this.getAuthHeaders()
    );
  }

  postNotifyStatus(fg): Observable<any> {
    return this.http.post(
      this.baseUrl + 'accounts/notifications/', fg,
      this.getAuthHeaders()
    );
  }



  // API With Auth Token
  private getAuthHeaders() {
    const token = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'Token ' + token,
      'Content-Type': 'application/json; charset=utf-8',
    });
    return { headers: httpHeaders };
  }
}
