import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrepCardModule } from '../../../@prep/shared/card/card.module';
import { ClickOutsideModule } from '../../../@prep/shared/click-outside/click-outside.module';
import { MaterialModule } from '../../../@prep/shared/material-components.module';
import { ScrollbarModule } from '../../../@prep/shared/scrollbar/scrollbar.module';
import { ToolbarFullscreenToggleComponent } from './toolbar-fullscreen-toggle/toolbar-fullscreen-toggle.component';
import { ToolbarNotificationsComponent } from './toolbar-notifications/toolbar-notifications.component';
import { ToolbarQuickpanelToggleComponent } from './toolbar-quickpanel-toggle/toolbar-quickpanel-toggle.component';
import { ToolbarSidenavMobileToggleComponent } from './toolbar-sidenav-mobile-toggle/toolbar-sidenav-mobile-toggle.component';
import { ToolbarUserComponent } from './toolbar-user/toolbar-user.component';
import { ToolbarComponent } from './toolbar.component';
import { ToolbarReleaseNotesComponent } from './toolbar-release-notes/toolbar-release-notes.component';
import { ToolbarResourcesComponent } from './toolbar-resources/toolbar-resources.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ScrollbarModule,
    FormsModule,
    ClickOutsideModule,
    PrepCardModule
  ],
  declarations: [
    ToolbarComponent,
    ToolbarUserComponent,
    ToolbarNotificationsComponent,
    ToolbarQuickpanelToggleComponent,
    ToolbarFullscreenToggleComponent,
    ToolbarSidenavMobileToggleComponent,
    ToolbarReleaseNotesComponent,
    ToolbarResourcesComponent
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {
}
