<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between" style="height: 60px;">
  <div fxLayout="row" fxLayoutAlign="start center">
    <prep-toolbar-sidenav-mobile-toggle (openSidenav)="openSidenav.emit()"></prep-toolbar-sidenav-mobile-toggle>
    <img *ngIf="centennial_bool" src="../../../assets/Logo/centennial-logo.png" height="45"
      style="margin-right: 20px;" />
    <!-- <img *ngIf="CE_bool" [src]="account.organization.logo" height="45" style="margin-right: 20px;" /> -->
    <img *ngIf="CE_bool" src="../../../assets/Logo/ce-logo.svg" height="45" style="margin-right: 20px;" />
    <h3> Welcome {{ account_user_name }}&nbsp;!&nbsp;<small *ngIf="isSuperAdmin">(Admin User)</small> </h3>

    <!-- <img src="../../../../assets/img/prep2.svg" height="50" /> -->

    <!-- <prep-toolbar-search-bar fxFlex="500px" fxHide fxShow.gt-md></prep-toolbar-search-bar> -->
  </div>

  <div fxLayout="row">



    <button class="button" fxHide fxShow.gt-sm mat-button type="submit" color="primary" (click)="clickGoToTraning()"
      fxFlex="grow" [disabled]="videobool" matTooltip="PREP TRAINING" matTooltipClass="table_tooltip_job_filename"
      matTooltipPosition="below">
      <mat-icon> video_library</mat-icon>&nbsp;&nbsp;<strong>PREP Training</strong>
    </button>

    <prep-toolbar-notifications *ngIf="router.url.includes('dashboard')" class="toolbar-button">
    </prep-toolbar-notifications>

    <span class="toolbar-button">
      <button class="button" fxHide fxShow.gt-sm mat-button fxFlex="grow" matTooltip="HELP"
        matTooltipClass="table_tooltip_job_filename" matTooltipPosition="below" (click)="HelpClick()">
        <mat-icon>help_outline</mat-icon>
        <!-- <mat-icon *ngIf="!helpWidgetBool">help_outline</mat-icon> -->
        <!-- <mat-icon *ngIf="helpWidgetBool">highlight_off</mat-icon> -->
      </button>
    </span>

    <prep-toolbar-release-notes class="toolbar-button"></prep-toolbar-release-notes>

    <prep-toolbar-fullscreen-toggle class="toolbar-button"></prep-toolbar-fullscreen-toggle>

    <prep-toolbar-search fxHide fxShow.gt-sm fxHide.gt-md class="toolbar-button"></prep-toolbar-search>



    <prep-toolbar-user class="toolbar-button"></prep-toolbar-user>

    <!-- <prep-toolbar-quickpanel-toggle (openQuickPanel)="openQuickPanel.emit()"
                                    class="toolbar-button"></prep-toolbar-quickpanel-toggle> -->
  </div>
</div>