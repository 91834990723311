

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';




import { ActivatedRoute, Router } from '@angular/router';


// Animation
import { fadeInUpAnimation } from '../../../../@prep/animations/fade-in-up.animation';
import { fadeInRightAnimation } from '../../../../@prep/animations/fade-in-right.animation';
// Browser Fingerprint to generate Unique ID
import Fingerprint from 'fingerprintjs2';
// Freshwork Service for Help widget Open / Close
// import { NgxFreshworkWidgetService } from '../../../widget/ngx-freshwork-widget/ngx-freshwork-widget.service';
// Services
import { PlatformUserService } from '../service/platform-user.service';
import { PlatformGlobalService } from '../../../layout/service/platform-global.service';
@Component({
  selector: 'prep-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [PlatformUserService, PlatformGlobalService],
  animations: [fadeInUpAnimation, fadeInRightAnimation],
})
export class LoginComponent implements OnInit {
 // analytics = (window.analytics = window.analytics || []);
  // User Login
  UserLoginFormGroup: FormGroup;
  etaToken: string;
  // To extract device unique ID
  resultFingerPrint: any;
  // loading Spinner
  is_loading = false;

  // Password Toggle Visibility
  inputType = 'password';
  visible = false;

  // Toggle Help Widget
  helpWidgetBool = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    @Inject(DOCUMENT) private window: Window,
    // private ngxFreshworkWidgetService: NgxFreshworkWidgetService,
    private platformuserService: PlatformUserService,
    private platformGlobalService: PlatformGlobalService
  ) {
    this.UserLoginFormGroup = this.fb.group({
      etaToken: '',
      id: '',
    });
    // Browser FingerPrint
    this.resultFingerPrint = async () => {
      const secure = await new Promise((resolve) => {
        Fingerprint.getV18((result, components) => resolve(result));
      });
      return secure;
    };
    this.resultFingerPrint().then((result) => {
      this.resultFingerPrint = result;
      this.UserLoginFormGroup.patchValue({
        id: result,
      });
    });
    console.log('Login Form', this.UserLoginFormGroup.value);
  }

  ngOnInit() {
    // Check user Offline / Online
    this.platformuserService.createOnline$().subscribe((isOnline) => {
      console.log('online======', isOnline);
      if (!isOnline) {
        this.snackbar.open(
          'Error:Please check Internet  Connection!!',
          'OK THANKS',
          {
            duration: 10000,
          }
        );
      }
    });
    console.log(
      'router snap shot before condition',
      this.route.snapshot.paramMap.get('etaToken')
    );
    if (this.route.snapshot.paramMap.get('etaToken')) {
      this.UserLoginFormGroup.patchValue({
        etaToken: this.route.snapshot.paramMap.get('etaToken'),
      });
      this.is_loading = true;
      console.log('User Login SSO Mard', this.UserLoginFormGroup.value);
      this.platformuserService.ssoUser(this.UserLoginFormGroup.value).subscribe(
        (response) => {
          console.log('Response SSO USER', response);
          this.is_loading = false;
          localStorage.setItem('token', response['token']);
          this.platformGlobalService.me = response['user'];
          this.router.navigate(['dashboard']);
        },
        (error) => {
          console.log('error', error);
          if (error.status === 400) {
            this.is_loading = false;
            this.snackbar.open('Incorrect Email Id or Password!', 'OK THANKS', {
              duration: 1500,
            });
            this.router.navigate(['/login']);
          } else if (error.status === 401) {
            this.snackbar.open(
              'User Allowed to Login on only one Device',
              'OK THANKS',
              {
                duration: 1500,
              }
            );
            this.router.navigate(['login']);
          } else if (error.status === 417) {
            this.is_loading = false;
            console.log('error info cuncurrent user', error.error.msg);
            if (error.error.msg) {
              this.snackbar.open(error.error.msg, 'OK THANKS', {
                duration: 10000,
              });
            }
          } else {
            this.is_loading = false;
            this.router.navigate(['login']);
          }
        }
      );
    } else {
      this.is_loading = false;
    }
  }
  // Login User
  send() {
    this.is_loading = true;
    // SSO For Standford University

    let urlMiami: string =
      'https://sso.cnfsp.moocpedia.com/simplesaml/module.php/core/authenticate.php?as=default-sp-ts';
    // let urlCeProd: string =
    //   'https://us-central1-sso-myathina-function.cloudfunctions.net/app/login?tenant=prepCEProd';
    const link = document.createElement('a');
    link.setAttribute('href', urlMiami);
    document.body.appendChild(link);
    link.click();
    link.remove();

    this.platformuserService.createOnline$().subscribe((isOnline) => {
      console.log('online======', isOnline);
      if (!isOnline) {
        this.snackbar.open(
          'Error:Please check Internet  Connection!!',
          'OK THANKS',
          {
            duration: 10000,
          }
        );
      }
    });
  }

  // Password Toggle Visibility
  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  clickRegisterNow() {
    this.router.navigate(['sign-up']);
  }
}
