<prep-loading-indicator></prep-loading-indicator>
<prep-backdrop #backdrop="prepBackdrop"></prep-backdrop>
<!-- <prep-config-panel-toggle (openConfig)="openConfigPanel()"></prep-config-panel-toggle> -->

<!-- QUICKPANEL -->
<prep-sidebar #configPanel="prepSidebar" [backdrop]="backdrop" [invisibleBackdrop]="true" position="right">
  <prep-config-panel></prep-config-panel>
</prep-sidebar>
<!-- END QUICKPANEL -->

<div class="container" fxLayout="column">
  <ng-container *ngIf="(toolbarPosition$ | async) === 'above-fixed'">
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </ng-container>

  <mat-sidenav-container autosize fxFlex>

    <!-- SIDENAV -->
    <mat-sidenav (closedStart)="closeSidenav()"
                 (openedStart)="openSidenav()"
                 [mode]="sidenavMode$ | async"
                 [opened]="sidenavOpen$ | async"
                 *ngIf="sideNavigation$ | async"
                 class="prep-collapsable-sidenav">
      <prep-sidenav [collapsed]="sidenavCollapsed$ | async" [expanded]="sidenavExpanded$ | async"></prep-sidenav>
    </mat-sidenav>
    <!-- END SIDENAV -->

    <mat-sidenav [(opened)]="quickPanelOpen" mode="over" position="end">
      <prep-quickpanel></prep-quickpanel>
    </mat-sidenav>

    <mat-sidenav-content class="content-container" fxLayout="column">
      <ng-container *ngIf="(toolbarPosition$ | async) === 'fixed'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
      </ng-container>

      <!-- CONTENT -->
      <div [class.scroll-disabled]="scrollDisabled$ | async"
           cdkScrollable
           class="content"
           fxFlex="auto"
           fxLayout="column">
        <ng-container *ngIf="(toolbarPosition$ | async) === 'static'">
          <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        </ng-container>

        <div class="content-inner" fxFlex="auto">
          <router-outlet></router-outlet>
        </div>

        <ng-container *ngIf="(footerPosition$ | async) === 'static'">
          <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
      </div>
      <!-- END CONTENT -->

      <ng-container *ngIf="(footerPosition$ | async) === 'fixed'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
      </ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <ng-container *ngIf="(footerPosition$ | async) === 'above-fixed'">
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </ng-container>
</div>

<ng-template #toolbar>
  <!-- TOOLBAR -->
  <prep-toolbar (openQuickPanel)="openQuickPanel()"
                [hasNavigation]="topNavigation$ | async"
                (openSidenav)="openSidenav()"
                *ngIf="toolbarVisible$ | async"
                fxFlex="none"></prep-toolbar>
  <!-- END TOOLBAR -->

  <!-- NAVIGATION -->
  <prep-navigation *ngIf="topNavigation$ | async"
                   fxFlex="none"></prep-navigation>
  <!-- END NAVIGATION -->
</ng-template>

<ng-template #footer>
  <!-- FOOTER -->
  <prep-footer fxFlex="none"></prep-footer>
  <!-- END FOOTER -->
</ng-template>

