import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GlobalUser, UserDashboardFiles } from '../model/global-user';

@Injectable({
  providedIn: 'root'
})
export class PlatformGlobalService {
  //  User Data
  private userSource = new BehaviorSubject<GlobalUser>(new GlobalUser());
  user = this.userSource.asObservable();

  set me(user: GlobalUser) {
    localStorage.setItem('account', JSON.stringify(user));
    this.userSource.next(user);
  }

  get myUserInfo(): GlobalUser {
    return this.userSource.getValue();
  }

  // Current User Data
  private CurrentuserSource = new BehaviorSubject<GlobalUser>(new GlobalUser());
  currentUser = this.userSource.asObservable();

  set setCUserInfo(cuser: GlobalUser) {
    this.CurrentuserSource.next(cuser);
  }

  get getCUserinfo(): GlobalUser {
    return this.CurrentuserSource.getValue();
  }


  //  User Files
  private dashboardUserSource = new BehaviorSubject<UserDashboardFiles>(new UserDashboardFiles());
  dashbaordUserFiles = this.dashboardUserSource.asObservable();

  set setStoreDashboardInfo(dashbaordUserFiles: UserDashboardFiles) {
    this.dashboardUserSource.next(dashbaordUserFiles);
  }

  get getStoreDashboardInfo(): UserDashboardFiles {
    return this.dashboardUserSource.getValue();
  }

  constructor() {
  }
}
