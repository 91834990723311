<div class="quickpanel">
  <h3>Import Tags for PDF Files</h3>
  <mat-form-field class="example-form-field" style="width: 100%;">
    <mat-label>Search More Resource </mat-label>
    <input matInput type="text">
    <button matSuffix mat-icon-button aria-label="Clear">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-nav-list>
    <h3 matSubheader>FREQUENTLY ASKED QUESTIONS</h3>
    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">How do you tag a table?</span>
      </p>
    </a>
    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">How do you tag tables with links, lists?</span>
      </p>
    </a>

    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">How to tag table of contents? </span>
      </p>
    </a>

    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">How to tag footnotes? </span>
      </p>
    </a>

    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">How and where to use continue feature? </span>
      </p>
    </a>

    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">How to tag formula/multiple formulae at once? or
          how to use Math API? </span>
      </p>
    </a>

    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">What are benefits of having a preview option and
          how to use view/edit option?</span>
      </p>
    </a>

    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">When to run redo OCR? </span>
      </p>
    </a>

    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">Use of drawing mode? </span>
      </p>
    </a>

    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">How to tag artifact in PREP? </span>
      </p>
    </a>


    <a href="javascript:" mat-list-item>
      <mat-icon matListItemIcon>play_circle&nbsp;</mat-icon>
      <p>
        <span style="font-weight: 500;font-size:16px;">How and where to use Group and Change icon?</span>
      </p>
    </a>
  </mat-nav-list>
</div>