import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuleWithProviders, Type } from '@angular/core';
import { FreshdeskWidgetModel } from './ngx-freshwork-widget.service.model';
import { NgxFreshworkWidgetService } from './ngx-freshwork-widget.service';

@NgModule()
export class NgxFreshworkWidgetModule { 
  static forRoot(
    FreshdeskWidgetConfig: Type<FreshdeskWidgetModel>
  ): ModuleWithProviders<NgxFreshworkWidgetModule> {
    return {
      ngModule: NgxFreshworkWidgetModule,
      providers: [
        { provide: FreshdeskWidgetModel, useClass: FreshdeskWidgetConfig },
        {
          provide: NgxFreshworkWidgetService,
          useClass: NgxFreshworkWidgetService,
          deps: [FreshdeskWidgetModel],
        },
      ],
    };
  }
}
