import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LIST_FADE_ANIMATION } from '../../../../@prep/shared/list.animation';

import { NotificationService } from './notification.service';

import { PlatformGlobalService } from '../../service/platform-global.service';
import { Subscription } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'prep-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  animations: [...LIST_FADE_ANIMATION],
  providers: [NotificationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarNotificationsComponent implements OnInit {
  notifications: any[];
  isOpen: boolean;
  notifyCount = 0;

  // number_of_notifications: number;

  constructor(
    private notificationService: NotificationService,
    private platformGlobalService: PlatformGlobalService,
  
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.notifications = [];
    this.notifyCount =
      this.platformGlobalService.myUserInfo.number_of_notifications;
    // Authorize

    // Authorize
    // this.currentUserService.GetCurrentUser().subscribe(
    //   (response) => {
    //     this.platformGlobalService.me = response;
    //     this.platformGlobalService.user.subscribe((me) => {
    //       this.notifyCount = me.number_of_notifications;
    //     });
    //   },
    //   (error) => {
    //     console.log('error', error)
    //   }
    // );

    this.notificationService.getNotifyData().subscribe(
      (res) => {
        console.log('me', res);
        this.notifications = res.result;

        this.notifications.forEach((element) => {
          if (element.read === false) {
            this.notifyCount = this.notifyCount + 1;
          }
        });
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  markAsRead(notification) {
    notification.read = true;
  }

  dismiss(notification, event) {
    event.stopPropagation();
    if (this.notifyCount > 0) {
      this.notifyCount = this.notifyCount - 1;
    }
    this.notifications.splice(this.notifications.indexOf(notification), 1);
  
    let formData = {
      id: notification.id,
      read: true,
    };
    this.notificationService.postNotifyStatus(formData).subscribe(
      (res) => {
        this.snackbar.open(
          'Notification marked as read Successfully',
          'OK THANKS',
          {
            duration: 10000,
          }
        );
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  markAllAsRead() {
    this.notifications.forEach((notification) => (notification.read = true));
  }
}
