import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../../sidenav/sidenav.service';
import { PlatformGlobalService } from '../../service/platform-global.service';
import { DashboardService } from 'src/app/pages/dashboard/service/dashboard.service';

@Component({
  selector: 'prep-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
})
export class ToolbarUserComponent implements OnInit {
  isOpen: boolean;

  constructor(
    private router: Router,
    private platformGlobalService: PlatformGlobalService,
    private dashboardService: DashboardService,
    private sidenavService: SidenavService
  ) {}

  ngOnInit() {}

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  logoutClicked() {
    console.log('User Info toolbar', this.platformGlobalService.getCUserinfo);
    if (this.platformGlobalService.getCUserinfo.email) {
      let sendUserInfo = {
        username: this.platformGlobalService.getCUserinfo.email,
      };
      this.dashboardService.logoutCuncurrentUser(sendUserInfo).subscribe(
        (response) => {
          if (response.msg) {
            localStorage.removeItem('token');
            localStorage.removeItem('account');
            this.sidenavService.items = [];
            this.router.navigate(['/login']);
          }
        },
        (error) => {
          console.log('error', error);
          localStorage.removeItem('token');
          localStorage.removeItem('account');
          this.sidenavService.items = [];
          this.router.navigate(['/login']);
        }
      );
    }
    // localStorage.removeItem('token');
    // localStorage.removeItem('account');
    // this.sidenavService.items = [];
    // this.router.navigate(['/login']);
  }
  profile() {
    console.log('welcome to profile');
    this.router.navigate(['/profile']);
  }
}
