<div class="sidenav" fxLayout="column">
  <div class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayoutAlign="start start">
      <img [routerLink]="['/']" style="cursor: pointer" src="../../../assets/splash-screen/prep-icon.svg" alt="prep home icon" height="45"  />
    </div>
    <div [routerLink]="['/']" class="sidenav-toolbar-logo">
      <h2>PREP</h2>
    </div>

    <span fxFlex>
      <!-- fill space -->
    </span>

    <div fxFlexAlign="center" fxHide fxShow.gt-md>
      <button (click)="toggleCollapsed()" class="sidenav-toolbar-collapse-toggle" mat-icon-button>
        <mat-icon *ngIf="!collapsed" class="sidenav-toolbar-collapse-icon">radio_button_checked</mat-icon>
        <mat-icon *ngIf="collapsed" class="sidenav-toolbar-collapse-icon">radio_button_unchecked</mat-icon>
      </button>
    </div>
  </div>

  <prep-scrollbar class="sidenav-items" fxFlex="auto">
    <div *ngIf="sidenavUserVisible$ | async" class="sidenav-user">
      <mat-icon class="list-item-icon">account_circle</mat-icon>
      <h4 class="sidenav-user-name">{{ account_user_name }}</h4>
      <h5 class="sidenav-user-email">{{ account_user_email }}</h5>

      <div class="sidenav-user-icons" fxLayout="row">
        <a mat-icon-button (click)="profile()">
          <mat-icon matTooltip="Profile" matTooltipClass="table_tooltip_job_filename" matTooltipPosition="below">
            account_circle</mat-icon>
        </a>
        <a (click)="logoutClicked()" mat-icon-button>
          <mat-icon matTooltip="Sign out" matTooltipPosition="below" matTooltipClass="table_tooltip_job_filename">
            exit_to_app</mat-icon>
        </a>
      </div>
    </div>

    <prep-sidenav-item *ngFor="let item of items$ | async" [item]="item" [level]="0"></prep-sidenav-item>
  </prep-scrollbar>
</div>