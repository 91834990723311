<div *ngIf="visible$ | async" class="footer" fxLayout="row">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="grow">
   <span><a style="text-decoration: underline;"
          href="https://prep.continualengine.com/privacy-policy/" target="_blank">Privacy
          Policy</a></span>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" fxFlex="grow">
    <span>Powered by Continual Engine </span>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <button (click)="hide()" mat-icon-button type="button">
      <mat-icon class="close">close</mat-icon>
    </button>
  </div>
</div>