import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'prep-toolbar-resources',
  templateUrl: './toolbar-resources.component.html',
  styleUrls: ['./toolbar-resources.component.scss'],
})
export class ToolbarResourcesComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
  
  }

  clickGoToResources() {
    this.router.navigate(['/resources']);
  }
}
