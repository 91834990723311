import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment.prod';

import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';
import { PendingInterceptorModule } from '../@prep/shared/loading-indicator/pending-interceptor.module';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';
// Login Module
import { LoginModule } from './pages/authentication/login/login.module';
// NGRX EXT
// import { StoreModule } from '@ngrx/store';
// import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// Reducers
// import { metaReducers, reducers } from './reducers';
// import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
// import { EffectsModule } from '@ngrx/effects';
// Freshdesk Widget
import { NgxFreshworkWidgetModule} from './widget/ngx-freshwork-widget/ngx-freshwork-widget.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';

import { UserIdleModule } from 'angular-user-idle';



export class FreshdeskConfig extends NgxFreshworkWidgetModule {
  locale: string;
  widgetId = 60000000525;
  callback(FreshworksWidget) {
    FreshworksWidget('hide');
  }
}

declare global {
  interface Window { analytics: any; }
}


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,
    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,
    LoginModule,
    NgxFreshworkWidgetModule.forRoot(FreshdeskConfig),
    ChartsModule,
    UserIdleModule.forRoot({idle: 10, timeout: 10, ping: 10})
    // STORE MODULE
    // StoreModule.forRoot(reducers, {
    //   metaReducers,
    //   runtimeChecks: {
    //     strictStateImmutability: true,
    //     strictActionImmutability: true,
    //     strictActionSerializability: true,
    //     strictStateSerializability: true,
    //   },
    // }),
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25,
    //   logOnly: environment.production,
    // }),
    // EffectsModule.forRoot([]),
    // StoreRouterConnectingModule.forRoot({
    //   stateKey: 'router',
    //   routerState: RouterState.Minimal,
    // }),
  
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
      } as MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      } as MatSnackBarConfig,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}


